<template>
  <div class="home_page">
    <topMenu></topMenu>
    <div class="logo_bg">
      <el-image :src="url">
        <div slot="placeholder">图片加载中</div>
      </el-image>
    </div>
    <div class="about_brief" @click="handleAnchor">
      <div class="brief_top">
        <h4>关于元年</h4>
        <span class="more">查看更多>></span>
      </div>
      <div style="font-size: 16px;line-height: 2;text-align: justify">
      上海元年金融信息服务有限公司成立于2015年，注册资本5000万元，是一家通过ISAE3402服务机构控制水平认证，并获得中国证券投资基金业协会份额登记与估值核算两项业务资格的独立三方基金服务机构。</div>
    </div>
    <!-- 元年介绍 -->
    <div class="padding110 introduce_bg">
      <div class="yn_introduce flex justify_content" id="introduce">
        <div class="left">
          <div class="yn_introduce_title">元年介绍</div>
          <div class="yn_introduce_content">
            <p>上海元年金融信息服务有限公司成立于2015年，是一家通过ISAE3402服务机构控制水平认证，并获得中国证券投资基金业协会份额登记（TA）、估值核算（FA）两项业务资格的独立三方基金服务机构（备案号A00044）。</p>
            <p style="padding-top: 12px;">多年来，元年金服专注于为国内外私募基金管理人提供基金行政管理服务，拥有专业的基金中后台运营系统和国内外基金服务团队，致力于为客户提供更高效的基金运营方案。同时，持续拓展人资服务业务线，为多家知名银行、理财子、证券的运营部门提供人资外包服务。目前服务团队超百人，各类私募投资基金和资管产品服务规模累计超千亿元。</p>
            <p style="padding-top: 12px;">元年金服秉承“赋能资管专业运营，助力国人财富增长”的使命；实践服务、求实、创新、执行、信义的企业价值观；立志成为最受信赖的第三方资管运营服务机构；做好资管行业的专心管家。</p>
          </div>
        </div>
        <div class="left">
          <div class="yn_introduce_title">服务范围</div>
          <div class="yn_introduce_tag">
            <div class="item flex align_items"
            v-for="(item, index) in introduceList"
            :key="index"
            @mouseover="changeStyle(true)"
            @mouseout="changeStyle(false)"
            @click="introduceHandle(item.path)">
              <img :src="item.icon" alt="" class="icon"><span>{{item.title}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Contact :dialog="contactStatus"></Contact>
    <!-- 加入我们、新闻资讯 -->
    <div class="padding110 introduce_bg">
      <!-- 加入我们 -->
      <div class="join_my" id="my">
        <div class="yn_introduce_title" style="padding-bottom: 0; padding-top: 50px;">加入我们</div>
        <div class="yn_join flex">
          <div class="join_item" 
          v-for="(item, index) in joinList" 
          :key="index" 
          @click="handRowClick(item)" 
          @mouseover="changeStyle(true)"
          @mouseout="changeStyle(false)">{{item.title}}</div>
        </div>
      </div>
      <!-- 新闻资讯 -->
      <div class="new" id="new">
        <div class="yn_introduce_title" style="padding-top: 60px;">新闻资讯</div>
        <el-carousel indicator-position="outside">
          <el-carousel-item v-for="(item, index) in newList" :key="index" interval="5000">
            <div class="slide">
              <div class="image-container" v-for="(sub, index1) in item.itemList" :key="index1" @click="handCompanyNewsClick(sub)">
                <img :src="sub.img" alt="" class="new_img">
                <p class="new_name">{{sub.name}}</p>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="footer" id="footer">
      <FooterBottom></FooterBottom>
    </div>
  </div>
</template>

<script>
import url from '../assets/home_page_bg.jpg';
import introduce_icon1 from '../assets/introduce_icon1.png';
import introduce_icon2 from '../assets/introduce_icon2.png';
import introduce_icon3 from '../assets/introduce_icon3.png';
import new_1 from '../assets/new_1.jpg';
import new_2 from '../assets/new_2.jpg';
import new_3 from '../assets/new_3.jpg';
import new_4 from '../assets/new_4.jpg';
import new_5 from '../assets/new_5.jpg';
import new_6 from '../assets/new_6.jpg';
import new_7 from '../assets/new_7.jpg';
import new_8 from '../assets/new_8.png';
import new_9 from '../assets/new_9.jpg';
import new_10 from '../assets/new_10.jpg';
import new_11 from '../assets/new_11.jpg';
import new_12 from '../assets/new_12.jpg';
import new_13 from '../assets/new_13.jpg';
import new_14 from '../assets/new_14.jpg';
import new_15 from '../assets/new_15.jpg';
import new_16 from '../assets/new_16.jpg';
import new_17 from '../assets/new_17.jpg';
import new_18 from '../assets/new_18.jpg';
import new_19 from '../assets/new_19.jpg';
import new_20 from '../assets/new_20.jpg';
import new_21 from '../assets/new_21.jpg';
import new_22 from '../assets/new_22.jpg';
import new_23 from '../assets/new_23.jpg';
import new_24 from '../assets/new_24.jpg';
import new_25 from '../assets/new_25.jpg';
import new_26 from '../assets/new_26.jpg';


import {positionList,associationNewsList,companyNewsList} from './constData'
import TopMenu from './menu'
import Contact from './contact'
import FooterBottom from './footer'

export default {
  name: 'HomePage',
  props: {

  },
  components: {TopMenu,Contact,FooterBottom},
  data() {
    return {
      url,
      screenHeight: 0,
      fontSize: 16,
      introduceList: [
        {
          icon: introduce_icon1,
          title: '人民币基金行政管理服务',
          path: '/services/RMB'
        },
        {
          icon: introduce_icon2,
          title: '美元基金行政管理服务',
          path: '/services/dollar'
        },
        {
          icon: introduce_icon3,
          title: '资管机构中后台运营全方位专业支持',
          path: '/services/manage'
        }
      ],
      joinList: [
        { id: 1, title: '基金会计 —— 上海/北京' },
        { id: 2, title: '交易支持(项目驻场) —— 上海' },
        { id: 3, title: '清算助理(项目驻场) —— 上海' },
        { id: 4, title: '基金会计(项目驻场) —— 上海' },
        { id: 5, title: '金融产品助理(项目驻场) —— 上海' },
        { id: 13, title: '数据验证与研究方向服务助理(项目驻场) —— 上海' },
        { id: 11, title: '信息披露(项目驻场) —— 上海' },
        { id: 6, title: '营销支持岗(项目驻场) —— 上海' },
        { id: 10, title: '桌面运维(项目驻场) —— 上海' },
        { id: 12, title: '数据管理岗-数据统计方向(项目驻场) —— 上海' },
        { id: 9, title: '数据管理岗-数据验证与研究方向(项目驻场) —— 上海' },
        { id: 7, title: '衍生品确权岗(项目驻场) —— 上海' },
        { id: 14, title: '数据统计方向服务助理(项目驻场)—— 上海' },
        { id: 8, title: '基金运营助理(项目驻场) —— 上海/福州' },
      ],
      newList: [
        {
          itemList: [
            {
              img: new_13,
              name: '私募管理人社保代缴模式终结！',
              link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652631506&idx=1&sn=3359b9654f8e7be57d41aea25368a060&chksm=8bbd98bebcca11a861266f04fe00d53d51258ee6da19be5ed9ec7f9d683e8359c76441ead220&token=963550505&lang=zh_CN#rd'
            },
            {
              img: new_5,
              name: '实务｜私募基金管理人能从事顾问服务吗？',
              link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652631359&idx=1&sn=9ed9dafec0e1e45cf1f0874502afde2d&chksm=8bbd9853bcca11453132adf0c793e655852de8402e3599ff1ba26adae8f8f1476828329d193d&token=963550505&lang=zh_CN#rd'
            },
            {
              img: new_26,
              name: '最新中基协处罚事项：私募股权基金财报未经审计',
              link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652630962&idx=1&sn=5241afbfc618db1ae68a935154686b11&chksm=8bbd86debcca0fc80fb768571069be0de3617154198de09ad3b5ce8d186602a376c68776a41d&token=34220234&lang=zh_CN#rd'
            },
          ],
        },
        {
          itemList: [
            {
              img: new_13,
              name: '中基协最新反馈：基金备案核查管理人每位员工近2年社保和工资',
              link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652630746&idx=1&sn=dc6226864bed64a1e491acacca97b975&chksm=8bbd87b6bcca0ea0ec3f47f4519fe40a5f4423d11bf4b2c167507460771ef89eb0e822538c2b&token=34220234&lang=zh_CN#rd'
            },
            {
              img: new_5,
              name: '协会最新纪律处分事项：普通员工不具备基金从业资格',
              link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652629650&idx=1&sn=2eb3fa65d390bd58f833adbdf1eece9d&chksm=8bbd83febcca0ae80f4284628ea252f0cd3def26033d679e34d56aa80609123b73f69433336c&token=34220234&lang=zh_CN#rd'
            },
            {
              img: new_12,
              name: '基金从业资格：一文读懂应知应会考核',
              link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652629179&idx=2&sn=b279f1307a1bd8a723c668aba8e8877d&chksm=8bbd81d7bcca08c14b46a294b0382033e3b19a77d92c8ce394b3036caa21445db103f3d34b8a&token=34220234&lang=zh_CN#rd'
            },
          ],
        },
        {
          itemList: [
            {
              img: new_20,
              name: '私募基金产品的风险等级应该如何评定？',
              link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652628896&idx=2&sn=ab1c1d12b8bcb4226483ba52eed6e002&chksm=8bbd8eccbcca07da19fb5427b7246aa271cdba235255bd3d8a024003764e7eca0312d759036e&token=34220234&lang=zh_CN#rd'
            },
            {
              img: new_20,
              name: '从中基协《私募基金登记备案动态（2024）》看管理人登记要点解析',
              link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652628728&idx=1&sn=1011589d964cf8d692bae93ef0afd927&chksm=8bbd8f94bcca0682d1e90975c3b77d1ca277b04782fd842cad605167873f4932e2691ec59d15&token=34220234&lang=zh_CN#rd'
            },
            {
              img: new_26,
              name: '以案说法｜如何判定私募基金是否涉嫌非法吸收公众存款罪',
              link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652627605&idx=1&sn=693e5f67b48164ba5a8f245ba031393b&chksm=8bbd8bf9bcca02ef05dc1797484564cb334c471b50e7cf138a4456d4ce964cd4f6f1d52829c7&token=1168861312&lang=zh_CN#rd'
            }
          ]
        },
        {
          itemList: [
            {
              img: new_13,
              name: '思维导图｜一图汇总分类现行有效的私募基金监管政策',
              link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652627586&idx=1&sn=4a2f5fb7bf4fcc04818d732237e9f527&chksm=8bbd8beebcca02f8907574ae227e9930c3c92a56ef3a632bc18693c74bbd866c2e639b888d64&token=1168861312&lang=zh_CN#rd'
            },
            {
              img: new_20,
              name: '协会流程简化｜综合梳理私募管理人重大事项变更最新要求',
              link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652627490&idx=1&sn=364ae9643a7a3b4bf151651a49160b4c&chksm=8bbd8b4ebcca0258a4e830f06b27364734e472ed3274aff67e70eab8af3e4559c491cb95d88e&token=1168861312&lang=zh_CN#rd'
            },
            {
              img: new_13,
              name: '私募风控负责人能不能兼任运营、财务等岗位？',
              link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652627490&idx=2&sn=e7231b0fdfd2166ab15e869ab813d428&chksm=8bbd8b4ebcca02582b34484bc3e4bf1a099bd2040f1b156c59b0396137dee9c1a775b64c1d8c&token=1168861312&lang=zh_CN#rd'
            }
          ],
        },
        {
          itemList: [
            {
              img: new_20,
              name: '必看｜《证券运作指引》实施后，中基协最新备案反馈意见及应对方案',
              link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652627369&idx=1&sn=b10b782745c5d7af548b2bf1fd17cd0e&chksm=8bbd88c5bcca01d3af82dccee1587c4541c4469e4e9a749e46df3aa6b9eaa1a97c0a04f7eb11&token=1168861312&lang=zh_CN#rd'
            },
            {
              img: new_1,
              name: '今年已有1155家私募注销 行业生态加速优化',
              link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652627099&idx=1&sn=4e8de1691b59fc091b413b5aba369cfb&chksm=8bbd89f7bcca00e1f18b59c7951084c71a9ca1a6597dc88f03711703406945d83842808ad253&token=1168861312&lang=zh_CN#rd'
            },
            {
              img: new_15,
              name: '中基协近期提醒汇总｜Ambers与工商信息不一致、员工不足5人、高管缺位、督促管理人合规运作等',
              link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652627099&idx=2&sn=81fee7ebe8512d5fa0b27620afef75c0&chksm=8bbd89f7bcca00e1a4ba8468841c0e6910b9d0483f5d30f016a7d6ff027b3e0f9afe36634dda&token=1168861312&lang=zh_CN#rd'
            }  
          ],
        }
      ],
      contactStatus: false,
      isHover: false,
      companyNewsList,
      associationNewsList,
      positionList,
    }
  },
  created() {
    this.screenHeight = window.innerHeight;
  },
  mounted() {
    this.updateFontSize();
    window.addEventListener('resize', this.updateFontSize);
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('resize', this.updateFontSize);
  },
  methods:{
    updateFontSize() {
      const ratio = window.devicePixelRatio || 1;
      this.fontSize = Math.round(16 * ratio); // 根据像素比例调整字体大小
    },
    handleResize() {
      this.screenHeight = window.innerHeight;
    },
    handRowClick(row){
      window.open(`/position?id=${row.id}`)
    },
    handAssociationNewsRowClick(row){
      window.open(row.link);
    },
    handCompanyNewsClick(row){
      window.open(row.link);
    },
    handleAnchor(){
      const element = document.querySelector('#introduce');
      const rect = element.getBoundingClientRect();
      const offset = 90; // 设置一个适当的偏移量，根据实际情况调整
      window.scrollTo({
        top: rect.top + window.pageYOffset - offset,
        behavior: 'smooth'
      });
    },
    introduceHandle(path) {
      this.$router.push(path).then(() => {
        window.scrollTo(0, 0);
      });
    },
    changeStyle(isHover) {
      this.isHover = isHover;
    },
  }
}
</script>

<style scoped lang="scss">
  /*大背景*/
  .logo_bg{
    position: relative;
  }
  .logo_bg .el-image{
    height: 100%;
    width: 100%;
  }
  .yn_introduce_title {
    color: #B01F24;
    font-size: 52px;
    padding-bottom: 40px;
    font-weight: bold;
  }
  /*元年介绍*/
  .introduce_bg {
    background: url("../assets/introduce_bg.png");
  }
  .yn_introduce {
    padding: 60px 0;
    .left {
      width: 46%;
      .yn_introduce_content {
        color: #252525;
        font-size: 20px;
        line-height: 35px;
        text-align: justify;
      }
      .yn_introduce_tag {
        .item {
          width: 100%;
          cursor: pointer;
          border: 1px solid #21374E;
          margin-top: 50px;
          font-size: 30px;
          height: 112px;
          padding: 0 50px;
          color: #21374E;
          .icon {
            width: 46px;
            height: 46px;
            margin-right: 15px;
          }
        }
        .item:hover {
          background: #21374E;
          color: #fff;
        }
        .item:first-child {
          margin-top: 0;
        }
      }
    }
  }
  // 加入我们
  .join_my {
    padding-top: 40px;
    .yn_join {
      flex-wrap: wrap;
      .join_item{
        margin-right: 2%;
        font-size: 20px;
        border: 1px solid #252525;
        padding: 12px 0 12px 15px;
        margin-top: 40px;
        cursor: pointer;
        transition: transform 0.3s;
      }
      .join_item:hover {
        background-color: #21374E;
        color: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        transform: scale(1.1);
      }
      .join_item:nth-child(1){
        width: 15%;
      }
      .join_item:nth-child(6), .join_item:nth-child(10), .join_item:nth-child(11), .join_item:nth-child(13) {
        width: 36%;
      }
      .join_item:nth-child(2), .join_item:nth-child(7), .join_item:nth-child(3), .join_item:nth-child(4), .join_item:nth-child(8) {
        width: 19%;
      }
      .join_item:nth-child(5), .join_item:nth-child(9){
        width: 20%;
      }
      .join_item:nth-child(12), .join_item:nth-child(14) {
        width: 24%;
      }
      .join_item:nth-child(5), .join_item:nth-child(9), .join_item:nth-child(12) {
        margin-right: 0;
      }
    }
  }
  // 新闻资讯
  .new {
    .slide {
      display: flex;
      justify-content: space-between;
      .image-container {
        width: 30%; /* 每个图片容器宽度占比 */
        margin-right: 5%; /* 最后一个图片容器无间距 */
        cursor: pointer;
        .new_img {
          width: 506px;
          height: 216px;
        }
        .new_name {
          font-size: 23px;
          color: #252525;
          margin-top: 15px;
          line-height: 35px;
        }
      }
      .image-container:last-child {
        margin-right: 0; /* 最后一个图片容器无间距 */
      }
      .image-container:hover .new_name{
        color: #B01F24;
        text-decoration: underline;
      }
    }
  }
</style>

<style scoped>
  .about_brief{
    position: absolute;
    right:4%;
    top: 66%;
    width: 554px;
    height: 265px;
    background: #21374e99;
    padding: 36px 84px 36px 70px;
    color: #E5E5E5;
    cursor: pointer;
  }
  h4{
    font-size: 28px;
  }
  .more{
    color: #E5E5E5!important;
  }
  .brief_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
  }
  /* .news,.about, .join{
    padding: 60px 0;
  } */
  /*新闻*/
  /* .news .content{
    display: flex;
    justify-content:space-between;
  }
  .news{
    height: 586px;
    background: url("../assets/news_bg.jpg");
  } */
  /*加入我们*/
  /* .join{
    height: 515px;
  } */

  /*关于*/
  /* .about{
    height: 658px;
    background: url("../assets/news_bg.jpg");
    line-height: 1.7;
  }
  .wrapper{
    width: 1186px;
    margin: 0 auto;
  }
  .content_box{
    display: flex;
    justify-content: space-between;
  }
  .about .left{
    font-size: 16px;
    width: 780px;
  }
  .about .left >div{
    margin-bottom: 12px;
  }
  .first_line{
    width: 752px;
    margin: 0 auto;
  }
  .about_content{
    border: 1px solid rgba(0,0,0,0.12);
    padding: 18px 14px;
  }
  .about_content:before{
    display: inline-block;
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #101010;
    vertical-align: super;
    margin-right: 5px;
  } */

  /*页面底部*/
  /* .footer{
    font-size: 12px;
  } */

</style>
<style>
  /* .news .el-table__cell, .join .el-table__cell{
    height: 54px!important;
    font-size: 18px;
  }
  .join th, .news .left th{
    background:#21374E!important;
    color: #E5E5E5;
  }
  .news .right th{
    background:#B01F24!important;
    color: #E5E5E5;
  }
  .el-image{
    overflow: inherit!important;
  }
  .news .cell{
    padding: 0 56px!important;
  } */
  
</style>

